import { TFunction } from "i18next";
import * as yup from "yup";

const phoneNumberRegex = /^[+\d](?:.*\d)?$/;

export const getValidation = (t: TFunction<"FormErrors">) => {
  return yup.object().shape({
    phone: yup
      .string()
      .optional()
      .trim()
      .matches(phoneNumberRegex, t("onlyNumbers") || "")
      .min(8, t("phone") || ""),
    zip: yup
      .string()
      .optional()
      .trim()
      .min(4, t("postcode") || ""),
    city: yup.string().trim(),
    address1: yup.string().optional().trim(),
    address2: yup.string().optional().trim(),
    company: yup.string().optional().trim().optional(),
    lastName: yup.string().optional().trim(),
    name: yup.string().optional().trim(),
    // province: yup.string().optional().trim(),
    email: yup.string().optional().trim(),
    firstName: yup.string().optional().trim(),
    countryCode: yup.string().optional().trim().length(2),

    customerId: yup.string().optional().trim(),
    isDefaultBillingAddress: yup.boolean().optional(),
    id: yup.string().optional(),
  });
};
